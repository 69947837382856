.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  backdrop-filter: blur(6px);
  padding: 0 20px;
  box-sizing: border-box;

  .content {
    max-width: 600px;
    min-width: 550px;
    height: auto;
    background-color: #fff;
    padding: 50px;
    border-radius: 14px;
    text-align: center;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
    position: relative;
    box-sizing: border-box;
    display: flex;
    gap: 10px;
    flex-direction: column;

    @media screen and (max-width: 700px) {
      min-width: 250px !important;
    }

    .alert {
      width: fit-content;
      margin-bottom: 10px;
      color: #e2626e;
    }

    .continueButton {
      margin-top: 30px;
    }

    input {
      outline: none;
      padding: 10px 20px;
      border-radius: 4px;
      border: 1px solid #e9e9e9;
      font-size: 16px;
      width: 100%;
      box-sizing: border-box;

      &.error {
        border: 1px solid #e2626e;
      }
    }

    button {
      width: fit-content;
      padding: 10px 20px;
      font-size: 16px;
      outline: none;
      border: none;
      border-radius: 4px;
      align-self: center;
      background-color: #e2626e;
      font-weight: bold;
      color: #fff;
      transition: 0.1s ease-in-out background-color;

      &:hover {
        cursor: pointer;
        background-color: #cb4e5a;
      }
    }

    .consent {
      margin-top: 30px;
    }

    .consentCheck {
      display: block;
      position: relative;
      padding-left: 45px;
      margin-bottom: 12px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      text-align: left;
      color: #555;

      &:hover {
        input ~ .checkmark {
          background-color: #ccc;
        }
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked {
          & ~ .checkmark {
            background-color: #e2626e;

            &:after {
              display: block;
            }
          }
        }
      }

      .checkmark:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
      }
    }
  }
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 4px;
  background-color: #eee;

  &:after {
    content: '';
    position: absolute;
    display: none;
  }
}
