.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.documentContainer {
  width: fit-content;
  height: 100vh;
}
