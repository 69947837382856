.pdfView {
  padding-top: 50px;
  @media screen and (max-width: 700px) {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.pdfActions {
  display: flex;
  position: fixed;
  bottom: 50px;
  margin: 0 auto;
  height: 50px;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  gap: 10px;
}


.topBar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px; 
  display: flex;
  align-items: center;
  justify-content: space-between; 
  padding-right: 20px; 
  width: 100%;
  background-color: #FFFFFF;
  border-bottom: 1px solid #dcdcdc; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  z-index: 1000;

  & .logo {
    height: 25px;
    margin-left: 20px;
    padding-top: 4px;
  }

  & button {
    margin-right: 20px;
    min-width: 90px; 
    padding: 10px 24px;
    font-size: 16px;
    border: none;
    background-color: #e2626e;
    color: #fff;
    border-radius: 4px;

    &.saved {
      background-color: #e9e9e9;
      color: #e2626e;
    }

    &:hover:not(:disabled):not(.saved) {
      background-color: #cb4e5a;
      cursor: pointer;
    }

    &:disabled {
      background-color: #e9e9e9;
      color: #000;

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  .rightGroup {
    display: flex;
    align-items: center;
    gap: 24px;

    svg {
      cursor: pointer;
    }
  }
}


.completionScreen {
width: 100vw;
background-color: #FFFFFF; 
font-family: "Axiforma";
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
height: 100vh;
text-align: center;

& .title {
  font-size: 32px;
  margin: 0; 
}

& .subtitle {
  font-size: 16px;
  color: #494848;
  margin-top: 5px;
  margin-bottom: 50px;
}

& .image {
  margin-bottom: 50px;
}

& .greySection { 
  width: 50%;
  background-color: #f9f9f9;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  }

  & .text1 {
    font-size: 20px;
    color: #333;
    margin: 5px 0 0;
  }
  
  & .text2 {
    font-size: 13px;
    color: #494848;
    margin-top: 5px;
  
    a {
      color: #e2626e;
      text-decoration: none;
    }
 }
}

@font-face {
font-family: 'Axiforma';
font-weight: 100;
src: local('Axiforma'), url('../../fonts/AxiformaBoldfont.woff2') format('woff2');
}