.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  gap: 10px;

  & > h1 {
    margin-bottom: 0px;
  }
}
