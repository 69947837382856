.pdfDocument {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0px;
}

.pdfPage {
  border: 1px solid #e9e9e9;
}
