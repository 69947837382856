.combobox {
  border: none;
  position: absolute;
  outline: 1px solid #e2626e;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  resize: none;

  &.required {
    background-color: #e2626e20;
  }
  &:focus {
    outline-width: 2px;
  }
}
