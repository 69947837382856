.dialogContainer {
  display: flex;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 99999999;
  overflow-y: auto;
  transition: all 0.3s cubic-bezier(0.33, 1, 0.68, 1);
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  padding-top: 16px;
  animation-name: onDialogOpen;
  animation-duration: 200ms;
  animation-timing-function: cubic-bezier(0.1, 0.87, 0.55, 1.12);

  &.animate {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    transition: all 200ms cubic-bezier(0.1, 0.87, 0.55, 1.12);
  }
}

.backdrop {
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.dialog {
  position: relative;
  display: block;
  background-color: #ffffff;
  padding: 24px 40px;
  min-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 10px;
  margin: auto;
  transition: all 0.5s cubic-bezier(0.33, 1, 0.68, 1);

  .dialogHeader {
    margin-bottom: 10px;
  }

  // Target global class defined in the library component
  :global(.nav-tabs) {
    margin-bottom: 0;

    :global(.nav-item) {
      flex: none;
      width: 120px;
    }
  }
}

.dialog-xsm {
  width: 400px;
}

.dialog-sm {
  width: 500px;
}

.dialog-lg {
  width: 700px;
}

.dialog-xlg {
  width: 900px;
}

.dialog-flush {
  width: 1100px;
  padding: 8px;
  background: transparent;

  .dialogHeader {
    margin: 0;
    padding: 36px;
  }

  .dialogClose {
    top: 3px;
    right: 3px;
    padding: 3px;
    background: white;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  }
}

.dialog ul {
  list-style: none;

  li:not(:global(.nav-item))::before {
    content: '\2022';
    color: #ffffff;
    font-weight: bold;
    display: inline-block;
    margin-left: -0.8em;
    font-size: 18px;
    height: 0;
    width: 1.2rem;
    position: relative;
    top: 2px;
  }
}

.dialogClose {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 30px;
  right: 40px;
  height: 25px;
  width: 25px;
  border-radius: 100%;
  cursor: pointer;
  outline: none;
}

.dialogClose * {
  font-size: 1.8rem !important;
}

