.signature {
  position: relative;
  border: 1px solid #e2626e;
  background-color: rgba(0, 0, 0, 0);
}

.signatureImage {
  position: absolute;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0);
}

.signatureDialogButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 8px;

  & button {
    border: none;
    padding: 10px 24px;
    font-size: 16px;
    background-color: #e2626e;
    color: #fff;

    &.clear {
      background-color: #e9e9e9;
      color: #e2626e;
    }

    &:hover:not(:disabled):not(.saved) {
      background-color: #cb4e5a;
      cursor: pointer;
    }

    &:disabled {
      background-color: #e9e9e9;
      color: #000;

      &:hover {
        cursor: not-allowed;
      }
    }
  }
}
