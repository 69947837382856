.radiobutton {
  position: absolute;
  cursor: pointer;
  height: 18px !important;
  width: 18px !important;
  box-sizing: border-box;
}

input[type='radio'] {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  margin: 0;
  cursor: pointer;

  &:checked {
    & ~ .checkmark {
      background-color: #e2626e;
      border-color: #e2626e;

      & svg {
        display: block;
      }
    }
  }

  &:focus-visible {
    & ~ .checkmark {
      box-shadow: 0 0 0 1px white, 0 0 0 3px #e2626e;
    }
  }
}

.checkmark svg {
  display: none;
  width: 100%;
  height: 100%;
  stroke-width: 4px;
  stroke: white;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: #eee;
  border: 1px solid #bbb;
  box-sizing: border-box;
  &.required {
    border-color: #e2626e;
    background-color: #fbebed;
  }
}
